import logo_branca from '../../assets/img/logo_branca.png';
import svg_teste from '../../assets/img/svg_teste.svg';
import screens from '../../assets/img/screens.gif';
import how_it_works from '../../assets/img/how_it_works.svg';
import flashcards from '../../assets/img/flashcards.svg';
import resources from '../../assets/img/resources.svg';
import institutions from '../../assets/img/institutions.svg';
import apple from '../../assets/img/apple.svg';
import play_store from '../../assets/img/play_store.svg';

import InfoItem from '../molecules/InfoItem';
import Presentation from './Presentation';
import './css/Body.css';
import Footer from './Footer';
import Plans from './Plans';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

const bodySections = [
  {
    id: 'how_it_works',
    title: 'Vincule alunos e professores a sua instituição',
    description:
      'De maneira fácil e simples, convide seus alunos a utilizar a plataforma e utilizar os diversos recursos disponíveis.',
    image: institutions,
    layoutType: 'sectionTp2',
  },
  {
    id: 'resources',
    title: 'Centralize sua comunicação',
    description:
      'Publique atividades, recebe perguntas e responda dúvidas de seus alunos de maneira organizada. Tudo isso em um lugar só!',
    image: resources,
    layoutType: 'sectionTp3',
  },
];

export default function Body() {
  return (
    <div className="full-content">
      <div className="first-content relative container px-4 mx-auto mt-20">
        <section
          id="main-content"
          className="relative flex flex-wrap align-middle items-center justify-center extend-bg"
          style={{ background: 'linear-gradient(180deg,#930197,#5452be)' }}
        >
          {' '}
          {/* Principal */}
          <div className="flex flex-col gap-4 w-1/2" style={{ minWidth: 'fit-content' }}>
            <img className="logo-first-content" src={logo_branca} width={571} alt="Logo Nyoo" />
            <span
              className="main-content-slogan"
              style={{
                color: 'white',
                fontSize: '24px',
                fontWeight: '600',
                maxWidth: '571px',
                width: 'auto',
              }}
            >
              Um novo jeito de conectar a educação
            </span>
            <span
              className="main-content-slogan-text"
              style={{
                color: 'white',
                fontSize: '16px',
                maxWidth: '571px',
                width: 'auto',
              }}
            >
              Cadastre instituições para você e seus alunos manterem contato mais profissional,
              dizendo adeus à grupos de Whatsapp e Telegram para compartilhar conteúdos de estudo.
            </span>
            <div className="flex flex-col md:flex-row gap-2 align-middle md:justify-start download-buttons">
              <a href="https://app.nyoo.app" className="m-auto md:m-0">
                <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 text-secondary-color">
                  <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0 hover:font-bold">
                    <span className="my-auto">Teste gratuitamente</span>
                    <ArrowTopRightOnSquareIcon className="ml-2 h-5 w-5 pb-0.5 inline-block" />
                  </span>
                </button>
              </a>
              {/* Make it vertically centered
              <div className="flex flex-col align-middle">
                <span className="text-white text-sm align-middle mr-4 mt-auto mb-auto font-light">
                  ou baixe agora
                </span>
              </div> */}
              <div className="flex flex-row align-middle justify-center md:justify-start">
                <a
                  href="https://apps.apple.com/br/app/nyoo/id6470413097"
                  className="mt-auto mb-auto"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="bg-gradient-to-br from-purple-500 to-pink-500 hover:from-purple-500 group-hover:to-pink-500 rounded-full flex align-middle justify-center mr-2 p-0.5">
                    <div className="bg-white hover:bg-opacity-0 rounded-full flex align-middle justify-center px-2 py-1 transition-all ease-in store-img">
                      <img
                        src={apple}
                        width={25}
                        alt="Apple Store"
                        style={{ marginTop: '1.12px', marginBottom: '1.12px' }}
                      />
                    </div>
                  </button>
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=com.nyoo"
                  className="mt-auto mb-auto"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="bg-gradient-to-br from-purple-500 to-pink-500 hover:from-purple-500 group-hover:to-pink-500 rounded-full flex align-middle justify-center mr-2 p-0.5">
                    <div className="bg-white hover:bg-opacity-0 rounded-full flex align-middle justify-center px-2 py-1 transition-all ease-in store-img">
                      <img
                        src={play_store}
                        width={25}
                        alt="Play Store"
                        style={{ marginTop: '4px', marginBottom: '4px' }}
                      />
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="flex align-middle justify-center">
            <img
              className="main-content-img"
              src={screens}
              height={58}
              width={571}
              alt="Logo Nyoo"
            />
          </div>
        </section>
      </div>
      {bodySections.map(item => (
        <section id={item.id} className="mt-20 mb-20">
          <InfoItem
            title={item.title}
            description={item.description}
            layoutType={item.layoutType}
            image={item.image}
          />
        </section>
      ))}
      <section className="flex align-middle justify-center">
        <a href="https://app.nyoo.app">
          <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 text-secondary-color">
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0 hover:font-bold">
              Teste gratuitamente agora mesmo
            </span>
          </button>
        </a>
      </section>
      <section id="who_we_are" className="lg:mt-40">
        <Presentation />
      </section>
      <section id="plans" className="lg:mt-40 mb-8">
        <Plans />
      </section>
      <section className="flex align-middle justify-center mb-24">
        <a href="https://app.nyoo.app">
          <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 text-secondary-color">
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0 hover:font-bold">
              Teste gratuitamente agora mesmo
            </span>
          </button>
        </a>
      </section>
      {/* Footer */}
      <section id="footer">
        <Footer />
      </section>
    </div>
  );
}
