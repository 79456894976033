import './css/Footer.css';

function Footer() {
  // Get current year
  const currentYear = new Date().getFullYear();
  return (
    <div className="container mx-auto general-container py-5">
      <div className="flexSpaceCenter ">
        <div className="ml-auto mr-auto pt-10 pb-10 text-center">
          <h4 className="text-base font-medium mb-1">© {currentYear} Nyoo Serviços Online LTDA</h4>
          <p className="text-sm">CNPJ: 41.287.510/0001-79</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
