'use client';

import { useEffect, useState } from 'react';
import { Dialog, DialogPanel, PopoverGroup } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo_cropped_square from '../../assets/img/logo_cropped_square.png';
import logo_colorida from '../../assets/img/logo_colorida.png';
import './css/Header.css';

const headerSections = [
  {
    name: 'Quem somos',
    href: '#who_we_are',
    order: 3,
  },
  {
    name: 'Como funciona',
    href: '#how_it_works',
    order: 1,
  },
  {
    name: 'Recursos',
    href: '#resources',
    order: 2,
  },
  {
    name: 'Planos',
    href: '#plans',
    order: 4,
  },
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      // Verifique se estamos no topo da página
      if (window.scrollY === 0) {
        window.history.pushState(null, '', '/');
        setActiveSection('');
        return;
      }

      headerSections.forEach(section => {
        const element = document.querySelector(section.href) as HTMLElement;
        if (element) {
          const { offsetTop, offsetHeight } = element;
          if (offsetTop <= scrollPosition && offsetTop + offsetHeight > scrollPosition) {
            setActiveSection(section.href.substring(1));
            window.history.pushState(null, '', `#${section.href.substring(1)}`);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    setActiveSection(id);
    window.history.pushState(null, '', `#${id}`);

    document
      .getElementById(id)
      ?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
  };

  return (
    <header className="bg-white">
      <nav
        aria-label="Global"
        className="fixed top-0 left-0 w-full z-50 flex items-center justify-between bg-white shadow-md sm:px-4 lg:px-8 text-secondary-color"
      >
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex-shrink-0 px-4">
            <a href="#" className="-m-1.5 p-1.5">
              <img alt="Nyoo Logo" src={logo_colorida} className="h-8 w-auto" />
            </a>
          </div>
          <div className="flex flex-row justify-between gap-2">
            <div className="flex-shrink-0 lg:justify-end my-auto lg:hidden">
              <a href="https://app.nyoo.app/">
                <button className="relative inline-flex items-center justify-center p-0.5 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 text-secondary-color">
                  <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0 hover:font-bold">
                    Acessar
                  </span>
                </button>
              </a>
            </div>
            <div className="flex lg:hidden px-4">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(true)}
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
          </div>
          <PopoverGroup className="hidden lg:flex lg:gap-x-12">
            {headerSections.map(item => (
              <a
                key={item.name}
                href={item.href}
                style={{ order: `${item.order}` }}
                className={`from-left text-sm font-semibold leading-6 text-gray-900 ${activeSection === item.href.substring(1) ? 'active' : ''}`}
                onClick={e => handleClick(e, item.href.substring(1))}
              >
                {item.name}
              </a>
            ))}
          </PopoverGroup>
          <div className="flex-shrink-0 hidden lg:flex lg:justify-end px-4">
            <a href="https://app.nyoo.app/">
              <button className="relative inline-flex items-center justify-center p-0.5 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 text-secondary-color">
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0 hover:font-bold">
                  Acessar plataforma
                </span>
              </button>
            </a>
          </div>
        </div>
      </nav>
      {/* MOBILE */}
      <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only"></span>
              <img alt="Logo Nyoo" src={logo_colorida} className="h-8 w-auto" />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-12 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {headerSections.map(item => (
                  <a
                    key={item.name}
                    href={item.href}
                    style={{ order: `${item.order}` }}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <div className="flex-shrink-0 lg:flex lg:justify-end">
                  <a href="https://app.nyoo.app/">
                    <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 text-secondary-color">
                      <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0 hover:font-bold">
                        Acessar plataforma
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
