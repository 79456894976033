import './App.css';
import Body from './components/organisms/Body';
import Header from './components/organisms/Header';

function App() {
  return (
    <>
      <Header />
      <Body />
    </>
  );
}

export default App;
