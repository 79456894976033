import './css/Presentation.css';
import presentation_image from './../../assets/img/who_we_are.svg';

const contentText: {
  semiTitle: string;
  title: string;
  contentTextBox: string;
} = {
  semiTitle: `Estude com eficiência e praticidade`,
  title: `Nyoo - A Plataforma Completa para sua Instituição de Ensino`,
  contentTextBox: `Transforme a experiência de ensino com nosso app inovador. Conecte-se com professores, gerencie suas tarefas, acompanhe seu progresso acadêmico e acesse recursos educativos, tudo em um só lugar. Simplifique sua vida estudantil com a tecnologia de ponta.`,
};

function Presentation() {
  return (
    <div className="container mx-auto general-container">
      <div className="flexSpaceCenter general-content">
        <div className="content-image">
          <div className="image-holder">
            <div className="image-definition">
              <img
                className="radius8"
                src={presentation_image}
                alt="Apresentação sobre as funcionalidades do Nyoo"
              />
            </div>
          </div>
        </div>
        <div className="content-text">
          <div className="mb-2">
            <h4 className="text-base font-medium mb-1">{contentText.semiTitle}</h4>
            <h2 className="text-3xl font-extrabold">{contentText.title}</h2>
          </div>
          <p className="text-sm">{contentText.contentTextBox}</p>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
