import './css/InfoItem.css';

type InfoItemProps = {
  title: string;
  description: string;
  image: any;
  layoutType: string;
};

function InfoItem({ title, description, image, layoutType }: InfoItemProps) {
  return (
    <>
      <div className={`section-group ${layoutType}`}>
        <div className="text-group" style={{ minWidth: 'fit-content' }}>
          <span className="info-item-title">{title}</span>
          <span className="info-item-description">{description}</span>
        </div>
        <div className="flex align-middle justify-center">
          <img src={image} height={58} width={571} alt="Logo Nyoo" />
        </div>
      </div>
    </>
  );
}

export default InfoItem;
