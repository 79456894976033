import './css/Plans.css';
import single_professor from './../../assets/img/single_professor.svg';
import small_school from './../../assets/img/small_school.svg';
import medium_school from './../../assets/img/medium_school.svg';
import large_school from './../../assets/img/large_school.svg';
import nyoo_logo from './../../assets/img/logo_cropped_square.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const plans: {
  title: string;
  image: string;
  studentLimit: number;
  monthlyPrice: number;
  yearlyPrice: number;
  free?: boolean;
  // features: string[];
}[] = [
  {
    title: 'Teste gratuitamente com todos os recursos',
    image: single_professor,
    studentLimit: 10,
    monthlyPrice: 0,
    yearlyPrice: 0,
    free: true,
    // features: ['Recursos básicos', 'Suporte por e-mail'],
  },
  {
    title: 'Indicado para professores particulares',
    image: single_professor,
    studentLimit: 30,
    monthlyPrice: 19.9,
    yearlyPrice: 199.9,
    // features: ['Recursos básicos', 'Suporte por e-mail', 'Suporte por telefone'],
  },
  {
    title: 'Indicado para cursinhos particulares',
    image: small_school,
    studentLimit: 75,
    monthlyPrice: 49.9,
    yearlyPrice: 499.9,
    // features: ['Recursos básicos', 'Suporte por e-mail', 'Suporte por telefone'],
  },
  {
    title: 'Indicado para instituições de ensino',
    image: medium_school,
    studentLimit: 150,
    monthlyPrice: 99.9,
    yearlyPrice: 999.9,
    // features: [
    //   'Recursos básicos',
    //   'Suporte por e-mail',
    //   'Suporte por telefone',
    //   'Personalização da plataforma',
    // ],
  },
  {
    title: 'Indicado para redes educacionais',
    image: large_school,
    studentLimit: 300,
    monthlyPrice: 149.9,
    yearlyPrice: 1499.9,
    // features: [
    //   'Recursos básicos',
    //   'Suporte por e-mail',
    //   'Suporte por telefone',
    //   'Personalização da plataforma',
    //   'Integração com sistemas de gestão escolar',
    // ],
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1150 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1150, min: 880 },
    items: 3,
  },
  small_tablet: {
    breakpoint: { max: 880, min: 630 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 630, min: 0 },
    items: 1,
  },
};

function Plans() {
  return (
    <div className="container mx-auto general-container">
      <h1 className="text-3xl font-extrabold ml-4 mt-20 plan-title">Nossos planos</h1>
      <p className="font-medium text-gray-400 mb-5 mt-5 ml-4">
        Os planos são cobrados por número total de alunos e professores cadastrados na sua
        instituição.
      </p>
      <div className="">
        <Carousel responsive={responsive}>
          {plans.map((plan, index) => (
            <div key={index} className="content-plan">
              {/* <div className="plan-image">
              <div className="plan-image-definition">
                <img src={plan.image} />
              </div>
            </div> */}
              <div className="plan-text">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col" style={{ color: '#930197' }}>
                    <h4 className="text-sm font-medium">Até</h4>
                    <h3 className="text-5xl font-extrabold">{plan.studentLimit}</h3>
                    <h4 className="text-base font-medium">Alunos</h4>
                  </div>
                  <div className="plan-image">
                    <div className="plan-image-definition">
                      <img src={nyoo_logo} alt="Nyoo logo" />
                    </div>
                  </div>
                </div>
                {plan.free ? (
                  <div className="flex flex-col subscription-price-group">
                    <h2 className="text-4xl font-bold subscriptions-price">Grátis</h2>
                    <h2 className="text-xl subscriptions-price">Já incluso</h2>
                  </div>
                ) : (
                  <div className="flex flex-col subscription-price-group">
                    <h2 className="text-3xl font-bold subscriptions-price">
                      {plan.monthlyPrice.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                      <span className="text-base font-normal">/mês</span>
                    </h2>
                    <h2 className="text-md font-bold subscriptions-price">
                      {plan.yearlyPrice.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                      <span className="text-base font-normal">/ano</span>
                    </h2>
                  </div>
                )}
                <h4 className="text-center font-medium my-auto text-gray-400">{plan.title}</h4>
                {/* Trocar cor com limite de aluno se quiser ver qual opção é melhor */}
                {/* <ul>
                {plan.features.map((feature, index) => (
                  <li key={index} className="text-sm">
                    {feature}
                  </li>
                ))}
              </ul> */}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Plans;
